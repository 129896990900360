"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = require("@/model/settings");
var _index = require("@/i18n/index.js");
var _vuex = require("vuex");
//
//
//
//
//
var _default = {
  data() {
    return {
      captchaObject: null,
      geeTestRegisterId: "",
      geeTestId: "",
      isOpen: true
    };
  },
  //   接受父组件传递的值，用来控制图形验证的加载
  props: ["isGeet", "isRegister"],
  methods: {
    async GtCaptcha() {
      const lang = (0, _index.getLanguage)() == "zh_CN" ? "zho" : (0, _index.getLanguage)() == "zh_HK" ? "zho-hk" : "eng";
      let _this = this;
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(75);
      if (!data) {
        console.log("无所需数据");
        return;
      }
      data.forEach(element => {
        if (element["type"] == "1" && element["platform"] == "3") {
          this.geeTestRegisterId = element["captcha_id"];
        }
        if (element["type"] == "2" && element["platform"] == "3") {
          this.geeTestId = element["captcha_id"];
        }
      });
      let captchaId = this.isRegister ? this.geeTestRegisterId : this.geeTestId;
      var handlerEmbed = function (captchaObj) {
        captchaObj.onReady(function () {
          _this.captchaObject = captchaObj;
        }).onSuccess(function () {
          var result = captchaObj.getValidate();
          let param = {
            captcha_output: result.captcha_output,
            gen_time: result.gen_time,
            lot_number: result.lot_number,
            pass_token: result.pass_token
          };
          _this.$emit("geetParam", param);
          captchaObj.reset();
        }).onError(function () {
          //your code
        });
      };
      //   初始化极验
      _this.$initGeetest4({
        language: lang,
        captchaId: captchaId,
        product: "bind"
      }, handlerEmbed);
    },
    openGtCaptcha() {
      let captcha_conf = JSON.parse(sessionStorage.getItem("captcha_conf"));
      this.isOpen = captcha_conf.status;
      // 页面创建，调用函数
      if (this.isOpen == 1) {
        this.GtCaptcha();
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  created() {
    this.openGtCaptcha();
  },
  mounted() {},
  watch: {
    // 监听参数的变化，调用极验
    isGeet: function () {
      if (this.isOpen == 1) {
        let that = this;
        // this.captchaObject.showCaptcha(); //显示验证码
        let wait = setInterval(function () {
          if (that.captchaObject !== null) {
            clearInterval(wait);
            that.captchaObject.showCaptcha();
          }
        }, 100);
      } else {
        let param = {
          captcha_output: "",
          gen_time: "",
          lot_number: "",
          pass_token: ""
        };
        this.$emit("geetParam", param);
      }
    },
    language: function (newValue, oldValue) {
      this.openGtCaptcha();
    }
  }
};
exports.default = _default;